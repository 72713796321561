<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form ref="bankTransactionForm" @submit.prevent="saveBankTransaction" class="custom-form">
                    <v-card :elevation="1" color="blue lighten-5">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar color="#546E7A" dark :elevation="1" height="30px">
                                    <v-toolbar-title class="subtitle-2">Bank Transaction Entry</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="py-0 mt-1">
                                    <v-row dense>
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1"> 
                                                <v-col cols="3">Tr Date</v-col>
                                                <v-col cols="9">
                                                    <v-menu
                                                        v-model="menu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="0"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="bankTransaction.transaction_date"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                autocomplete="off"
                                                            >
                                                                <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="bankTransaction.transaction_date"
                                                            @input="menu = false"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Account</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        item-text="display_text"
                                                        item-value="id"
                                                        @focus="$store.dispatch('bankAccount/getBankAccounts')"
                                                        :loading="$store.getters['bankAccount/loading']"
                                                        :items="$store.getters['bankAccount/bankAccounts']"
                                                        v-model="selectedBankAccount"
                                                        :rules="[v => !!v]"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Tr Type</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :items="['Deposit', 'Withdraw']"
                                                        v-model="bankTransaction.transaction_type"
                                                        :rules="[v => !!v]"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-3">Amount</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.number="bankTransaction.amount"
                                                        :rules="[v => !!v]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-3">Note</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="bankTransaction.note"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3"></v-col>
                                                <v-col cols="9">
                                                    <v-row no-gutters>
                                                         <v-col cols="6" class="pr-1">
                                                            <v-btn @click="resetForm" height="30px" dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-btn type="submit" :loading="waitingForSave" height="30px" dark block color="light-blue darken-2">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-row no-gutters justify="center">
                                                <v-col cols="7">
                                                    <v-card class="d-flex justify-center">
                                                        <fieldset class="current__balance">
                                                            <legend>Current Balance</legend>
                                                            <div class="content">
                                                                <div class="currency">Taka</div>
                                                                <div class="amount">{{ $store.getters['bankTransaction/currentBankBalance'] }}</div>
                                                            </div>
                                                        </fieldset>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="mt-1">
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="transactionHeaders"
                    :loading="$store.getters['bankTransaction/loading']"
                    :items="$store.getters['bankTransaction/bankTransactions']"
                    :search="searchBankTransactions"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Bank Transaction List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Bank Transaction"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                    v-model="searchBankTransactions"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-if="userType != 'user'" v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="editBankTransaction(item)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="openDeleteDialog(item.id)" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
                </v-data-table>
            </v-col>
        </v-row>

        <delete-confirm ref="deleteDialog" @confirm="deleteBankTransaction"></delete-confirm>

    </v-container>
</template>

<script>
import DeleteConfirm from '@/components/Confirm'

export default {
    name: 'BankTransaction',
    components: {
        DeleteConfirm,
    },
    data: ()=> ({
        menu: false,
        transactionHeaders: [
            { text: 'Tr Date', value: 'transaction_date' },
            { text: 'Acc Name', value: 'bank_account.account_name' },
            { text: 'Acc Number', value: 'bank_account.account_number' },
            { text: 'Bank Name', value: 'bank_account.bank_name' },
            { text: 'Tr Type', value: 'transaction_type' },
            { text: 'Note', value: 'note' },
            { text: 'Amount', value: 'amount' },
            { text: 'Saved By', value: 'added_by.name' },
            { text: 'Action', value: 'action' },
        ],
        searchBankTransactions: '',
        selectedBankAccount: null,
        bankTransaction: {
            transaction_date: new Date().toISOString().slice(0, 10),
            transaction_type: '',
            amount: 0,
            note: '',
        },
        waitingForSave: false,
        bankTransactionEditId: null,
        bankTransactionDeleteId: null,
        userType: ''
    }),
    watch: {
        selectedBankAccount(val) {
            if (val == null) {
                this.$store.commit('bankTransaction/changeCurrentBankBalance', 0)
                return
            }
            this.$store.dispatch('bankTransaction/getCurrentBankBalance', {id: val.id})
        }
    },
    created() {
        this.$store.dispatch('bankTransaction/getBankTransactions')
        let userData = JSON.parse(localStorage.getItem('_userData'))
        this.userType = userData.user_type     
    },
    methods: {
        async saveBankTransaction() {
            if (!this.$refs.bankTransactionForm.validate()) return

            this.waitingForSave = true
            this.bankTransaction.bank_account_id = this.selectedBankAccount.id
            if (this.bankTransactionEditId == null) {
                let res = await this.$store.dispatch('bankTransaction/saveBankTransaction', {
                    url: 'add-bank-transaction',
                    data: this.bankTransaction
                })
                if (res) this.resetForm()
            } else {
                let res = await this.$store.dispatch('bankTransaction/saveBankTransaction', {
                    url: 'update-bank-transaction',
                    data: {...this.bankTransaction, id: this.bankTransactionEditId}
                })
                if (res) this.resetForm()
            }

            this.waitingForSave = false
        },

        editBankTransaction(item) {
            this.bankTransactionEditId = item.id
            this.selectedBankAccount = item.bank_account
            Object.keys(this.bankTransaction).map(k => this.bankTransaction[k] = item[k])
        },

        openDeleteDialog(id) {
            this.bankTransactionDeleteId = id
            this.$refs.deleteDialog.dialog = true
        },

        deleteBankTransaction() {
            this.$store.dispatch('bankTransaction/deleteBankTransaction', {id: this.bankTransactionDeleteId})
            this.$refs.deleteDialog.dialog = false
            this.bankTransactionDeleteId = null
        },

        resetForm() {
            this.selectedBankAccount = null
            this.bankTransaction.transaction_date = new Date().toISOString().slice(0, 10)
            this.bankTransaction.transaction_type = ''
            this.bankTransaction.amount = 0
            this.bankTransaction.note = ''
            this.bankTransactionEditId = null
            this.$refs.bankTransactionForm.resetValidation()
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
    .v-messages {
        flex: 1 1 auto;
        font-size: 12px;
        min-height: 0px !important;
        max-height: 0px!important;
        min-width: 1px;
        position: relative;
    }
    .current__balance {
        width: 100%;
        margin: 3px 10px 5px;
        padding: 0 5px;
        .content {
            text-align: center;
            font-size: 18px;
            .currency {
                margin: 5px 0;
                font-size: 22px;
            }
            .amount {
                margin-bottom: 2px;
            }
        }
    }
</style>